















import Vue from "vue";
import moment from "moment";
import { Toast } from "vant";
import { dispatch, Coupon } from "@/store";
import { share } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      drawActivity: {
        id: 3,
        create_time: "2024-04-14T12:12:47.645333+08:00",
        update_time: "2024-04-14T12:12:47.645334+08:00",
        code: "2",
        is_use: true,
        limit_coin: 0,
        allow_user_ids: [],
        expire_time: "2024-04-28T12:12:41+08:00",
        title: "2",
        desc: "2",
      } as any,
      claimedList: [] as { id: string; uuid: string }[],
    };
  },
  computed: {
    AMCodeList(): Coupon[] {
      return (this.drawActivity.coupon_infos || []).filter(
        (coupon: Coupon) => coupon.coupon.category === "early_bird"
      );
    },
    couponList(): Coupon[] {
      return (this.drawActivity.coupon_infos || []).filter(
        (coupon: Coupon) => coupon.coupon.category === "special_offer"
      );
    },
    time(): string {
      return `活动截止时间 : ${moment(this.drawActivity.expire_time).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`;
    },
  },
  methods: {
    getDrawActivity() {
      const { code } = this.$route.params;
      if (code) {
        if (this.loading) return;
        this.loading = true;
        dispatch.drawGetActivity(code).then((res) => {
          console.log(res);
          if (res.activity_type === "public") {
            this.loading = false;
            this.drawActivity = res;
            share({
              title: res.title,
              desc: res.description,
              link: window.location.href,
              imgUrl: "https://m.angrymiao.com/logo-medium.png",
            });
          } else {
            this.$router.replace(this.$paths.home);
          }
        });
      }
    },
    onCouponClick(coupon: Coupon) {},
    getClaimed(uuid: string) {},
    onClaimedClick(coupon: Coupon) {},
  },
  created() {
    this.getDrawActivity();
  },
  beforeDestroy() {
    share({
      title: "Angry Miao",
      desc: "在 Angry Miao 发掘更多新奇",
      link: window.location.href,
      imgUrl: "https://m.angrymiao.com/logo-medium.png",
    });
  },
});
